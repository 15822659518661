import LoginBg from "../../img/login-bg.png";
import Logo from "../../img/logo.png";

import React, { useEffect, useState, useRef } from "react";
import { useRecoilState } from "recoil";
import { CompanyRegister, toastAtom } from "../../recoil";
import { useCompanyRegisterActions } from "../../recoil/api/auth";
import { useForm, Controller } from "react-hook-form";
import CommonModal from "../modal/CommonModal";
import Stiputation from "../policy/Stipulation";
import Privacy from "../policy/Privacy";
import { Link, useNavigate } from "react-router-dom";
import SearchCompanyModal from "../common/SearchCompanyModal";
import { exceptThisSymbols } from "../employee/Register";
import { emailRegex } from "../../utils/regex";
const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4}$/;

export const Register = () => {
  const [user, setUser] = useRecoilState(CompanyRegister);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [isCheck1ModalOpen, setIsCheck1ModalOpen] = useState(false);
  const [isCheck2ModalOpen, setIsCheck2ModalOpen] = useState(false);
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);

  const [emailCustom, setEmailCustom] = useState(false);
  const [emailHead, setEmailHead] = useState("");
  const [emailDomain, setEmailDomain] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);

  const [isBNVerified, setIsBNVerified] = useState(false);
  const [isIDVerified, setISIDVerified] = useState(false);

  const [phoneCheck, setPhoneCheck] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [authNum, setAuthNum] = useState("");

  const companyRegisterActions = useCompanyRegisterActions();
  const [toast, setToast] = useRecoilState(toastAtom);
  const navigate = useNavigate();

  const [isPageLoad, setPageLoad] = useState(false);
  const [isCompanyIdError, setCompanyIdError] = useState("");
  const [isSMSCertified, setSMSCertified] = useState(false);
  const [isSMSFailReason, setSMSFailReason] = useState("");
  const [isEmailCheck, setIsEmailCheck] = useState();
  const [alreadyPhone, setAlreadyPhone] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const pw = useRef({});
  pw.current = watch("pw", "");
  pw.confirm = watch("pw_confirm", "");

  const changeValue = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value || "",
    });
  };

  useEffect(() => {
    if (emailHead.length !== 0 && emailDomain.length !== 0) {
      const user_email = `${emailHead}@${emailDomain}`;
      setValue("user_email", user_email);
    } else {
      setValue("user_email", "");
    }
  }, [emailHead, emailDomain]);

  const onChageModal1State = () => {
    setIsCheck1ModalOpen(false);
  };
  const onChageModal2State = () => {
    setIsCheck2ModalOpen(false);
  };

  const onSubmit = async (data) => {
    if (!isIDVerified) {
      alert("아이디 중복체크를 해주세요.");
      return false;
    }

    if (!isPhoneVerified) {
      alert("휴대폰 인증을 해주세요.");
      return false;
    }

    if (pw.current !== pw.confirm) {
      alert("비밀번호가 일치하지 않습니다.");
      return false;
    }

    if (!emailVerified) {
      alert("이메일 중복체크를 해주세요.");
      return false;
    }

    if ((!checked1 || !checked2) && isPageLoad) {
      alert("모든 약관에 동의해주세요.");
      return false;
    }

    let params = {
      ...data,
      email: data.user_email,
      accept_terms: "1",
      accept_personal_info: "1",
    };

    const res = await companyRegisterActions.register(params);
    if (res.status === 200) {
      setToast({ ...toast, on: true, msg: "가입이 완료되었습니다." });
      navigate("/");
    }
  };

  const emailCheck = async (email, domain) => {
    setEmailVerified(false);
    if (!email) {
      setIsEmailCheck(1); // 이메일 미입력
    } else if (!domain) {
      setIsEmailCheck(2); // 도메인 미입력
    } else {
      const reqEmail = `${email}@${domain}`;
      if (!emailRegex.test(reqEmail)) {
        alert("이메일을 올바르게 입력하세요.");
        return;
      }
      const res = await companyRegisterActions.emailCheck({ email: reqEmail });
      if (!res) {
        setIsEmailCheck(3); //사용중인 이메일
      } else {
        setIsEmailCheck(0);
        setEmailVerified(true);
      }
    }
  };

  return (
    <div>
      <div className="con-login">
        <img className="loimage" src={LoginBg} alt="배경" />
        <div className="flex-log login-l">
          <div className="l-cont">
            <img src={Logo} alt="로고" />
            <p>재택근무 인사관리 플랫폼</p>
            <span>(주)브이드림</span>
          </div>
        </div>
        <div className="flex-log login-r register-table">
          <div
            className="r-cont"
            style={{ marginBottom: "40px", marginTop: "90px" }}
          >
            <div className="lotit">
              <h3>회원가입</h3>
              <span>(기업회원)</span>
              <p>플립에 오신 것을 환영합니다.</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <table className="table lotable" style={{ borderTop: "none" }}>
                <tbody>
                  <tr>
                    <td>
                      <input
                        {...register("name", { required: true })}
                        placeholder="이름을 입력해주세요."
                      />
                      {errors.name?.type === "required" && (
                        <span className="register-warning">
                          이름을 입력하세요.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="input-group">
                        <input
                          {...register("company_name", {
                            required: true,
                          })}
                          disabled={true}
                          className="form-control form-control-sm"
                          placeholder="회사명을 검색해주세요."
                        />
                        <span
                          className="input-group-btn"
                          onClick={() => setIsSearchCompanyModalOpen(true)}
                        >
                          <a>검색</a>
                        </span>
                        <input
                          {...register("COMP_CD", {
                            required: true,
                          })}
                          disabled={true}
                          style={{ display: "none" }}
                        />
                      </div>
                      {!watch("COMP_CD") && isPageLoad && (
                        <span className="register-warning">
                          회사명을 검색해주세요.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="input-group">
                        <input
                          {...register("companyId", {
                            required: true,
                            pattern: /^[A-za-z0-9]{6,}$/,
                          })}
                          className="form-control form-control-sm"
                          placeholder="아이디를 입력해주세요."
                          onChange={() => {
                            setISIDVerified(false);
                          }}
                          // disabled={isIDVerified}
                        />
                        <span
                          className="input-group-btn"
                          onClick={async () => {
                            const company_id = watch("companyId").trim();
                            const user_id_regx = /^[A-za-z0-9]{6,}$/;

                            setISIDVerified(false);
                            setCompanyIdError("");

                            if (company_id.length === 0) {
                              setCompanyIdError("noInput");
                              return;
                            }

                            if (company_id.length <= 5) {
                              setCompanyIdError("minimumLength");
                              return;
                            }

                            if (!user_id_regx.test(company_id)) {
                              setCompanyIdError("regularExpression");
                              return;
                            }
                            const res = await companyRegisterActions.idCheck({
                              id: company_id,
                            });
                            setISIDVerified(res);
                          }}
                        >
                          <a>중복확인</a>
                        </span>
                      </div>
                      <div className="alert-w id_check">
                        <i>!</i>
                        <span className="register-warning">
                          이미 사용중인 아이디 입니다.
                        </span>
                      </div>
                      {isIDVerified && (
                        <span className="register-ok">
                          사용 가능한 아이디 입니다.
                        </span>
                      )}
                      {errors.companyId?.type === "pattern" && (
                        <span className="register-warning">
                          아이디는 최소 6자이상 입력해주세요.
                        </span>
                      )}

                      {errors.companyId?.type === "required" && (
                        <span className="register-warning">
                          아이디를 입력해주세요.
                        </span>
                      )}

                      {isCompanyIdError === "noInput" && (
                        <span className="register-warning">
                          아이디를 입력해주세요.
                        </span>
                      )}

                      {isCompanyIdError === "minimumLength" && (
                        <span className="register-warning">
                          아이디는 6자리 이상 입력해주세요.
                        </span>
                      )}

                      {isCompanyIdError === "regularExpression" && (
                        <span className="register-warning">
                          아이디는 영문 숫자만 사용가능합니다.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        {...register("pw", {
                          required: true,
                          maxLength: 15,
                          pattern:
                            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,15}$/,
                        })}
                        type="password"
                        className="form-control"
                        placeholder="!,@,#,$,%를 포함한 비밀번호를 입력해주세요.(영문/숫자/특수문자 조합 6~15자)"
                      />
                      {errors.pw?.type === "pattern" && (
                        <span className="register-warning">
                          영문/숫자/특수문자 조합 6~15자를 입력하셔야 합니다.
                        </span>
                      )}

                      {errors.pw?.type === "required" && (
                        <span className="register-warning">
                          비밀번호를 입력해주세요.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        {...register("pw_confirm", {
                          required: true,
                          maxLength: 15,
                        })}
                        type="password"
                        className="form-control"
                        placeholder="비밀번호를 한번 더 입력해주세요."
                      />
                      {pw.current !== pw.confirm && (
                        <span className="register-warning">
                          비밀번호가 일치하지 않습니다.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="input-email">
                        <input
                          {...register("user_email", {
                            required: true,
                            pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          })}
                          style={{ display: "none" }}
                        />
                        <input
                          style={{ width: "28%" }}
                          type="text"
                          // className="form-control"
                          name="email_head"
                          placeholder="이메일 주소를 입력해주세요."
                          onChange={(e) => setEmailHead(e.target.value)}
                          onInput={(e) => {
                            setEmailVerified(false);
                            setIsEmailCheck();
                            // const regExp = /[^0-9a-zA-Z]/g;
                            if (emailRegex.test(e.target.value)) {
                              e.target.value = e.target.value.replace(
                                emailRegex,
                                ""
                              );
                            }
                          }}
                          value={emailHead}
                        />
                        <span>@</span>
                        <input
                          type="text"
                          placeholder="선택해주세요"
                          name="email_domain"
                          readOnly={!emailCustom}
                          onChange={(e) => setEmailDomain(e.target.value)}
                          value={emailDomain}
                        />
                        <select
                          style={{ float: "inherit", marginLeft: "5px" }}
                          name="email_domain"
                          onChange={(e) => {
                            setEmailVerified(false);
                            setIsEmailCheck();
                            if (e.target.value === "custom") {
                              setEmailCustom(true);
                            } else {
                              setEmailDomain(e.target.value);
                            }
                          }}
                        >
                          <option value="" disabled selected>
                            선택
                          </option>
                          <option value="gmail.com">gmail.com</option>
                          <option value="hanmail.net">hanmail.net</option>
                          <option value="hotmail.com">hotmail.com</option>
                          <option value="nate.com">nate.com</option>
                          <option value="naver.com">naver.com</option>
                          <option value="yahoo.co.kr">yahoo.co.kr</option>
                          <option value="custom">직접입력</option>
                        </select>
                        <span className="input-group-btn">
                          <a
                            onClick={() => {
                              emailCheck(emailHead, emailDomain);
                            }}
                          >
                            중복확인
                          </a>
                        </span>
                      </div>
                      {isEmailCheck === 1 && (
                        <span className="register-warning">
                          이메일을 입력해주세요.
                        </span>
                      )}
                      {isEmailCheck === 2 && (
                        <span className="register-warning">
                          도메인을 선택해주세요
                        </span>
                      )}
                      {isEmailCheck === 3 && (
                        <span className="register-warning">
                          이미 사용중인 이메일 입니다.
                        </span>
                      )}
                      {isEmailCheck === 0 && (
                        <span className="register-ok">
                          사용 가능한 이메일 입니다.
                        </span>
                      )}
                      {errors.user_email?.type === "pattern" && (
                        <span className="register-warning">
                          이메일을 올바르게 입력하세요.
                        </span>
                      )}
                      {!watch("user_email") && isPageLoad && (
                        <span className="register-warning">
                          이메일 주소를 입력해주세요.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="input-group mb-3">
                        <input
                          {...register("tel", {
                            required: true,
                            pattern:
                              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4}$/,
                          })}
                          disabled={isPhoneVerified}
                          className="form-control form-control-sm"
                          placeholder="휴대폰번호를 -없이 입력해주세요."
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*)\./g, "$1");
                          }}
                          maxLength={15}
                          type="number"
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          style={{ width: "68%" }}
                        />
                        <span
                          className="input-group-btn"
                          onClick={async () => {
                            if (!isPhoneVerified) {
                              if (!phoneRegex.test(watch("tel"))) {
                                alert("휴대폰 번호를 정확하게 입력하세요.");
                                return false;
                              }

                              const res =
                                await companyRegisterActions.phoneCheckRequest({
                                  tel: watch("tel"),
                                });
                              if (res) {
                                setPhoneCheck(true);
                                setAlreadyPhone(false);
                              } else {
                                setAlreadyPhone(true);
                              }
                            }
                          }}
                        >
                          <a style={{ width: "30%" }}>
                            {phoneCheck ? "재전송" : "인증번호발송"}
                          </a>
                        </span>
                      </div>
                      {alreadyPhone && (
                        <span className="register-warning">
                          이미 사용중인 휴대폰번호 입니다.
                        </span>
                      )}
                      {phoneCheck && (
                        <div className="input-group">
                          <input
                            value={authNum}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*)\./g, "$1");
                            }}
                            onChange={(e) => setAuthNum(e.target.value)}
                            disabled={isPhoneVerified}
                            className="form-control form-control-sm"
                            name="pass_num"
                            placeholder="인증번호를 입력해주세요."
                            maxLength={5}
                            type="number"
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                          />
                          <span
                            className="input-group-btn"
                            onClick={async () => {
                              setSMSFailReason("");
                              if (!isPhoneVerified) {
                                const res =
                                  await companyRegisterActions.phoneCheck({
                                    tel: watch("tel"),
                                    authNum,
                                  });
                                if (res.result) {
                                  setIsPhoneVerified(true);
                                } else {
                                  setSMSCertified(true);
                                  setSMSFailReason(res.data);
                                }
                              }
                            }}
                          >
                            <a>인증하기</a>
                          </span>
                        </div>
                      )}
                      {errors.tel?.type === "required" && (
                        <span id="alert-danger" className="register-warning">
                          휴대폰 번호를 입력하세요.
                        </span>
                      )}
                      {errors.tel?.type === "pattern" && (
                        <span id="alert-danger" className="register-warning">
                          번호 형식이 일치하지 않습니다.
                        </span>
                      )}
                      <div className="alert-w tel_check_error">
                        <i>!</i>
                        <span id="alert-danger" className="register-warning">
                          인증번호가 맞지않습니다.
                        </span>
                      </div>
                      {isPhoneVerified && (
                        <span className="register-ok">
                          인증이 완료되었습니다.
                        </span>
                      )}
                      {isSMSCertified && (
                        <span id="alert-danger" className="register-warning">
                          {isSMSFailReason}
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        {...register("birthday", {
                          required: true,
                        })}
                        type="date"
                        className="form-control data-placeholder"
                        max={"9999-12-31"}
                        required
                        data-placeholder="회원가입(기업회원)하려는 회원의 생년월일을 입력해주세요."
                        aria-required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div
                        className="checkbt macheck"
                        style={{ marginRight: 30 }}
                      >
                        <input type="checkbox" className="w-che" />
                        <span
                          className={checked1 && checked2 ? "on" : "off"}
                          onClick={() => {
                            if (checked1 && checked2) {
                              setChecked1(false);
                              setChecked2(false);
                            } else {
                              setChecked1(true);
                              setChecked2(true);
                            }
                          }}
                        ></span>
                        <label
                          htmlFor="remember"
                          className="label"
                          onClick={() => {
                            if (checked1 && checked2) {
                              setChecked1(false);
                              setChecked2(false);
                            } else {
                              setChecked1(true);
                              setChecked2(true);
                            }
                          }}
                        >
                          모든 항목에 동의
                        </label>
                      </div>
                      <div
                        className="checkbt macheck"
                        style={{ marginRight: 30 }}
                      >
                        <input
                          type="checkbox"
                          className="w-che"
                          onClick={() => setChecked1(!checked1)}
                        />
                        <span
                          className={checked1 ? "on" : "off"}
                          onClick={() => setChecked1(!checked1)}
                        ></span>
                        <label
                          htmlFor="remember"
                          className="label"
                          onClick={() => setChecked1(!checked1)}
                        >
                          이용약관
                        </label>
                        <a
                          className="watch"
                          style={{
                            marginLeft: 8,
                            marginTop: 2,
                            cursor: "pointer",
                          }}
                          onClick={() => setIsCheck1ModalOpen(true)}
                        >
                          [보기]
                        </a>
                      </div>
                      <div className="checkbt macheck">
                        <input type="checkbox" className="w-che" />
                        <span
                          className={checked2 ? "on" : "off"}
                          onClick={() => setChecked2(!checked2)}
                        ></span>
                        <label
                          htmlFor="remember"
                          className="label"
                          onClick={() => setChecked2(!checked2)}
                        >
                          개인정보 수집 및 이용
                        </label>
                        <a
                          className="watch"
                          style={{
                            marginLeft: 8,
                            marginTop: 2,
                            cursor: "pointer",
                          }}
                          onClick={() => setIsCheck2ModalOpen(true)}
                        >
                          [보기]
                        </a>
                      </div>
                    </td>
                    <td>
                      {(!checked1 || !checked2) && isPageLoad && (
                        <span className="register-warning">
                          모든 약관에 동의해주세요.
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="tdbutt">
                    <td colSpan={2}>
                      <button
                        type="submit"
                        className="lobutt on"
                        onClick={() => setPageLoad(true)}
                      >
                        회원가입
                      </button>
                    </td>
                  </tr>
                  <tr className="tdbutt">
                    <td colSpan={2}>
                      <Link to="/register-select">
                        <button type="button" className="lobutt">
                          취소
                        </button>
                      </Link>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </form>
          </div>
        </div>
      </div>

      <CommonModal
        open={isCheck1ModalOpen}
        onChangeModalState={onChageModal1State}
        title="이용약관"
      >
        <Stiputation />
      </CommonModal>
      <CommonModal
        open={isCheck2ModalOpen}
        onChangeModalState={onChageModal2State}
        title="개인정보 수집 및 이용"
      >
        <Privacy />
      </CommonModal>
      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(e) => {
          setValue("company_name", e.company_name);
          setValue("COMP_CD", e.COMP_CD);
        }}
      />
    </div>
  );
};

export default Register;
