import { useEffect, useState } from "react";
import "../../css/toast.css";
// import dreamCafe from "../../img/dream_cafe.png";
// import happybean from "../../img/happybean.png";
import req_doc_noti from "../../img/req_doc_noti.jpg";

const CommonImageModal = ({
  open,
  setOpen = () => {},
  onChangeModalState = () => {},
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
    onChangeModalState(false);
  };
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div>
      <div style={{ display: isOpen ? "block" : "none" }} id="modal">
        <div id="modalwin" style={{ position: "relative" }}>
          <button
            id="modalclose"
            style={{ margin: "16px 16px", position: "absolute", right: 0 }}
            onClick={() => closeModal()}
            alt="닫기"
          >
            &times;
          </button>

          <img
            src={req_doc_noti}
            // id="modalsec"
            // className="dream_cafe"
            // onClick={() =>
            //   window.open("https://happybean.naver.com/fundings/detail/F1785")
            // }
            alt="필수 서류 제출 확인하셨나요?"
            // role="button"
            // aria-label="해피빈 펀딩 참여하기"
            style={{
              width: "100%",
              height: "auto",
              // cursor: "pointer",
              borderRadius: "8px",
              display: "inline",
            }}
          />
          {/* <div id="modaltable ">{children}</div> */}

          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  );
};

export default CommonImageModal;
